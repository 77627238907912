import ApiService from '@/services/api.service'
import { mapGetters } from 'vuex'
import moment from 'moment'

const phpserialize = require('php-serialize')

export default {
    props: {
        index: Number,
    },
    data() {
        return {
            model: {},
            fieldValues: [],
            showPassword: this.$store.getters.getModels[this.index].type !== 'password',
            search: null,
        }
    },
    watch: {
        '$store.state.models': {
            deep: true,
            immediate: true,
            handler() {
                this.model = this.getModels[this.index]
            },
        },
        '$store.state.locale': function() {
            this.createElementsByType()
        },
    },
    computed: {
        ...mapGetters([
            'getModels',
            'getForm',
        ]),
        fieldValue: {
            //Info: Use the computed property to get sequetial values
            get() {

                //logger.log(`get fieldValue while remove items: `, this.getForm[this.model.targetKey])
                //logger.log(`get model while remove items: `, this.model)

                //Info: Define default key and value for form in store
                let value = this.getForm[this.model.source]

                //Info: Convert int to bool for switch, checkbox field
                if (this.model.element === 'switch' || this.model.element === 'checkbox')
                    return !!+value

                //Info: Get value from stored form with specific defined key
                else if (this.model.element === 'select' && this.getForm[this.model.targetKey])
                    return this.getForm[this.model.targetKey]

                //Info: Get value from stored form with specific defined key
                else if (this.model.element === 'combobox' && this.getForm[this.model.targetKey]) {
                    if (this.model.hasOwnProperty('serialize'))
                        return phpserialize.unserialize(this.getForm[this.model.serialize])
                }
                //Info: No specific handling needed, so return the default defined key from stored form
                else {
                    if (this.model.hasOwnProperty('moment') && this.model.moment)
                        return moment(this.getForm[this.model.source]).format(this.model.moment)
                    else
                        return this.getForm[this.model.source]
                }
            },
            set(newValue) {
                //Info: Modulize payloady, because in some field types the key may a different one (e.g. select)
                let payload = { key: this.model.source, value: newValue }

                //Info: Handle the setters
                if (this.model.element === 'switch' || this.model.element === 'checkbox') {
                    payload.key = this.model.source
                    payload.value = newValue ? 1 : 0
                }

                //Info: Set the form key to defined target key, because the payload comes from another api array with another key
                if (this.model.element === 'select' && newValue) {
                    payload.key = this.model.targetKey
                    payload.value = newValue
                }

                if (this.model.element === 'combobox' && newValue) {
                    payload.key = this.model.targetKey

                    if (this.model.hasOwnProperty('serialize'))
                        payload.value = phpserialize.serialize(newValue)

                    this.fieldValues = newValue
                }

                logger.log(`set fieldValue while remove items: `, newValue)

                //Info: Update the stored form with defined payload
                this.$store.commit('UPDATE_FORM', payload)
            },
        },
        rules() {
            const rulesReturn = []

            if (this.model.hasOwnProperty('rules') && this.fieldValue !== null && this.fieldValue !== undefined) {
                const rules = this.model.rules
                const fieldName = this.model.rules.filedName

                //Info: It must be queried for value in every rule, because when changing tabs, value is still undefined at the first moment
                if (rules.hasOwnProperty('email') && rules.email) {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi
                    rulesReturn.push(value => value !== undefined && pattern.test(value) || this.$i18n.t('common.validation.validEmail', { field: fieldName }))
                }

                if (rules.hasOwnProperty('phone') && rules.phone) {
                    const pattern = /^\+\d{1,2}/
                    rulesReturn.push(value => value !== undefined && pattern.test(value) || this.$i18n.t('common.validation.din5008', { field: fieldName }))
                }

                if (rules.hasOwnProperty('url') && rules.url) {
                    const urlRegex = new RegExp('(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})', 'g')
                    rulesReturn.push(value => value !== undefined && urlRegex.test(value) || this.$i18n.t('common.validation.validURL', { field: fieldName }))
                }

                if (rules.hasOwnProperty('min') && rules.min)
                    rulesReturn.push(value => value !== undefined && value.length >= rules.min || this.$i18n.t('common.validation.atLeastChars', { field: fieldName, n: rules.min }))

                if (rules.hasOwnProperty('max') && rules.max)
                    rulesReturn.push(value => value !== undefined && value.length <= rules.max || this.$i18n.t('common.validation.maxChars', { field: fieldName, n: rules.max }))

                if (rules.hasOwnProperty('range') && rules.range.length)
                    rulesReturn.push(value => value !== undefined && (value >= rules.range[0] && value <= rules.range[1]) || this.$i18n.t('common.validation.rangeBetween', { field: fieldName, a: rules.range[0], b: rules.range[1] }))
            }

            return rulesReturn
        },
    },
    methods: {
        removeClearableItem(item) {
            let tempArr = this.fieldValue
            tempArr.splice(item.index, 1)

            this.fieldValue = tempArr
            this.fieldValues = tempArr
        },
        fieldFocused(event) {
            if (this.model.type === 'password')
                this.$refs.textField.$el.querySelector(event.target.localName).select()
        },
        createFieldItemsArray(data) {
            const arr = []

            if (data.some(v => typeof v === 'object')) {

                for (let i in data) {
                    if (data.hasOwnProperty(i)) {
                        const item = data[i]

                        arr.push({
                            text: item[this.model.text],
                            value: item[this.model.value],
                        })
                    }
                }
            }
            else if (data.some(v => typeof v === 'string' || typeof v === 'number')) {
                for (let i in data) {
                    if (data.hasOwnProperty(i)) {
                        const item = data[i]

                        arr.push({
                            text: item,
                            value: i,
                        })
                    }
                }
            }

            return arr
        },
        async getAsyncFiledValues() {
            const path = this.model.api

            if (path) {
                const response = await ApiService.fetchSourceForOptions(path)

                if (response)
                    return this.createFieldItemsArray(response)
            }
            else
                return []
        },
        createElementsByType() {
            this.model = this.getModels[this.index]

            //logger.log(`this.model.hasOwnProperty('serialize'): `, (this.model.hasOwnProperty('serialize')))
            //logger.log(`this.model.hasOwnProperty('api'): `, (this.model.hasOwnProperty('api')))
            //logger.log(`this.model.hasOwnProperty('list'): `, (this.model.hasOwnProperty('list')))

            if (this.model.element === 'select' || this.model.element === 'combobox') {
                //Info: Get async options for field with multiple values (options) from api
                if (this.model.hasOwnProperty('api') && !this.model.hasOwnProperty('list') && !this.model.hasOwnProperty('serialize')) {
                    this.getAsyncFiledValues()
                        .then(arr => {

                            //logger.log(`##############------------->>> this is arr in getAsyncFiledValues: `, arr)

                            //Info: Define the key from the stored form that should be the value of the selected field
                            this.fieldValue = this.getForm[this.model.targetKey]

                            //logger.log(`##############------------->>> this is i18nLabel: ${ this.model.i18nLabel }`)

                            if (this.model.hasOwnProperty('i18nLabel') && this.model.i18nLabel) {
                                arr.forEach(value => {

                                    if (value.hasOwnProperty('text') && value.text)
                                        value.text = this.$i18n.t(`common.fields.select.${ value.text.toLowerCase() }`)
                                })
                            }

                            //Info: Put the fetched array in fieldValues
                            this.fieldValues = arr
                        })
                }
                //Info: Get a static list for fields with multiple values (options) from the model itself
                else if (this.model.hasOwnProperty('list') && !this.model.hasOwnProperty('api') && !this.model.hasOwnProperty('serialize')) {
                    this.fieldValue = this.getForm[this.model.targetKey]
                    this.fieldValues = this.createFieldItemsArray(this.model.list)
                }
                //Info: Get an array from a PHP serialized string for fields with multiple values (options) from the database field
                else if (this.model.hasOwnProperty('serialize') && !this.model.hasOwnProperty('api') && !this.model.hasOwnProperty('list')) {

                    //logger.log(`this is arr in combobox -> serialize: `, this.getForm[this.model.serialize])

                    if (this.getForm[this.model.serialize]) {
                        this.fieldValue = phpserialize.unserialize(this.getForm[this.model.serialize])
                        this.fieldValues = this.createFieldItemsArray(this.fieldValue)
                    }
                    else {
                        this.fieldValue = []
                        this.fieldValues = [
                            {
                                text: null,
                                value: null,
                            },
                        ]
                    }
                }
                else {
                    this.fieldValue = this.getForm[this.model.targetKey]
                    this.fieldValues = [
                        {
                            text: null,
                            value: null,
                        },
                    ]
                }
            }
        },
    },
    created() {
        this.createElementsByType()
    },
}