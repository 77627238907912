<template>
    <div>
        <v-textarea
            v-if="model.hasOwnProperty('permission') ? model.permission() : true"
            v-model="fieldValue"
            :value="fieldValue"
            ref="textareaField"
            :auto-grow="model.autoGrow || true"
            :no-resize="model.noResize || false"
            :label="model.label || ''"
            autocomplete="false"
            :hint="model.hint || ''"
            :append-icon="model.type !== 'password' ? (model.appendIcon || '') : (showPassword ? 'mdi-eye' : 'mdi-eye-off')"
            :append-outer-icon="model.appendOuterIcon || ''"
            :autofocus="model.autofocus || false"
            :clearable="model.clearable || false"
            :clear-icon="model.clearIcon || ''"
            :counter="model.counter || false"
            :disabled="model.disabled || false"
            :loading="model.loading || $store.getters.getLoaders.fields || false"
            :messages="model.messages || []"
            :placeholder="model.placeholder || ''"
            :prefix="model.prefix || ''"
            :prepend-icon="model.prependIcon || ''"
            :suffix="model.suffix || ''"
            :readonly="model.readonly || false"
            :rules="rules"
            :validate-on-blur="model.hasOwnProperty('validateOnBlur') ? model.validateOnBlur : false"
        />
    </div>
</template>

<script>
import field from '@/mixins/field.mixin'

export default {
    name: 'TextareaField',
    mixins: [field],
}
</script>
