<template>
    <v-text-field
        v-if="model.hasOwnProperty('permission') ? model.permission() : true"
        v-model="fieldValue"
        :value="fieldValue"
        @focus="fieldFocused"
        ref="textField"
        :type="showPassword ? (model.type === 'password' ? 'text' : model.type) : 'password'"
        :label="model.label || ''"
        autocomplete="false"
        :hint="model.hint || ''"
        :append-icon="model.type !== 'password' ? (model.appendIcon || '') : (showPassword ? 'mdi-eye' : 'mdi-eye-off')"
        :append-outer-icon="model.appendOuterIcon || ''"
        @click:append="showPassword = !showPassword"
        :autofocus="model.autofocus || false"
        :clearable="model.clearable || false"
        :clear-icon="model.hasOwnProperty('clearIcon') && model.clearIcon ? model.clearIcon : ''"
        :counter="model.hasOwnProperty('counter') && model.counter ? model.counter : false"
        :disabled="model.hasOwnProperty('disabled') && model.disabled ? model.disabled : false"
        :loading="$store.getters.getLoaders.hasOwnProperty('fields') ? $store.getters.getLoaders.fields : false"
        :messages="model.hasOwnProperty('messages') && model.messages.length ? model.messages : []"
        :placeholder="model.hasOwnProperty('placeholder') && model.placeholder ? model.placeholder : ''"
        :prefix="model.hasOwnProperty('prefix') && model.prefix ? model.prefix : ''"
        :prepend-icon="model.hasOwnProperty('prependIcon') && model.prependIcon ? model.prependIcon : ''"
        :suffix="model.hasOwnProperty('suffix') && model.suffix ? model.suffix : ''"
        :readonly="model.hasOwnProperty('readonly') && model.readonly ? model.readonly : false"
        :rules="rules && rules.length ? rules : []"
        :validate-on-blur="model.hasOwnProperty('validateOnBlur') ? model.validateOnBlur : false"
    />
</template>

<script>
import field from '@/mixins/field.mixin'

export default {
    name: 'TextField',
    mixins: [field],
}
</script>
