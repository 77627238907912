<template>
    <div>
        <v-combobox
            v-if="model.hasOwnProperty('permission') ? model.permission() : true"
            class="mb-1"
            v-model="fieldValue"
            :items="fieldValues"
            :type="model.type || 'text'"
            :color="$store.getters.getColors.secondary1"
            :append-icon="model.hasOwnProperty('appendIcon') && model.appendIcon ? model.appendIcon : ''"
            :label="model.label"
            :loading="$store.getters.getLoaders.hasOwnProperty('fields') ? $store.getters.getLoaders.fields : false"
            :hide-selected="model.hideSelected || true"
            :hide-details="model.hideDetails || true"
            :multiple="model.multiple || true"
            :persistent-hint="model.persistentHint || true"
            :small-chips="model.hideSelected || true"
            :deletable-chips="model.deletableChips || true"
            :no-filter="model.noFilter || true"
            :menu-props="{value: false}"
        >
            <template v-slot:selection="data">
                <v-chip
                    :input-value="data.selected"
                    close
                    class="my-2"
                    close-icon="mdi-close"
                    text-color="white"
                    small
                    :color="$store.getters.getColors.secondary1"
                    @click:close="removeClearableItem(data)"
                >
                    {{ data.item }}
                </v-chip>
            </template>
        </v-combobox>
    </div>
</template>

<script>
import field from '@/mixins/field.mixin'

export default {
    name: 'Combobox',
    mixins: [field],
}
</script>