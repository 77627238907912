<template>
    <div>
        <v-checkbox
          v-if="model.hasOwnProperty('permission') ? model.permission() : true"
          v-model="fieldValue"
          :value="fieldValue"
          false-value="0"
          input-value="1"
          ref="checkboxField"
          :color="$store.getters.getColors.secondary1"
          :label="model.hasOwnProperty('label') && model.label ? model.label : ''"
          :append-icon="model.hasOwnProperty('appendIcon') && model.appendIcon ? model.appendIcon : ''"
          :background-color="model.hasOwnProperty('backgroundColor') && model.backgroundColor ? model.backgroundColor : ''"
          :disabled="disabled()"
          :hint="model.hasOwnProperty('hint') && model.hint ? model.hint : ''"
          :loading="$store.getters.getLoaders.hasOwnProperty('fields') ? $store.getters.getLoaders.fields : false"
          :messages="model.hasOwnProperty('messages') && model.messages.length ? model.messages : []"
          :rules="rules && rules.length ? rules : []"
          :validate-on-blur="model.hasOwnProperty('validateOnBlur') ? model.validateOnBlur : false"
        />
    </div>
</template>

<script>
import field from '@/mixins/field.mixin'

export default {
    name: 'CheckboxField',
    mixins: [field],
    methods: {
        disabled() {
            if ('disabled' in this.model) {
                if (typeof this.model.disabled === 'function')
                    return !!this.model.disabled()

                return !!this.model.disabled
            }

            return false
        },
    },
}
</script>