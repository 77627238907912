import communicate from '@/services/communicate.service'

export default {

    /**
     *
     * @param path
     * @returns {Promise<*|{warning}|{body}|*[]>}
     */
    async fetchSourceForOptions(path) {
        return await communicate.request(enums.GET, path) || []
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}|*[]>}
     */
    async variousActions(payload) {
        return await communicate.request(enums.POST, 'variousactions', payload)
    },
}