<template>
    <v-row class="mt-2">
        <v-col
            class="py-0 pl-4 mt-1"
            v-for="(model, index) in $store.getters.getModels"
            :key="`model-${index}`"
            :cols="getProps(model).cols"
        >
            <component
                v-if="handlePermission(model)"
                :is="`${model.element[0].toUpperCase()}${model.element.substring(1).toLowerCase()}Field`"
                :index="index"
            />
        </v-col>
    </v-row>
</template>

<script>
import _ from 'lodash'

const fields = {}
const fieldDefinitions = require.context('./Felder', false, /^\.\/([\w-_]+)Field\.vue$/)

_.each(fieldDefinitions.keys(), (key) => {
    const componentName = key.replace(/^\.\//, '').replace(/\.vue/, '')
    fields[componentName] = fieldDefinitions(key).default
})

export default {
    name: 'Form',
    components: fields,
    methods: {
        getProps(model) {

            const values = {
                cols: 12,
                newRow: false,
            }

            if (model.hasOwnProperty('cols'))
                values.cols = model.cols

            if (model.hasOwnProperty('newRow') && model.newRow)
                values.newRow = true

            if (model.hasOwnProperty('divider'))
                values.divider = model.divider

            return values
        },
        handlePermission(model) {
            if (model && model.hasOwnProperty('permission'))
                return model.permission()

            return true
        },
    },
}
</script>