<template>
    <v-switch
        v-if="model.hasOwnProperty('permission') ? model.permission() : true"
        v-model="fieldValue"
        :value="fieldValue"
        ref="switchField"
        false-value="0"
        input-value="1"
        :color="$store.getters.getColors.secondary1"
        :label="model.label || ''"
        :append-icon="model.appendIcon || ''"
        :background-color="model.backgroundColor"
        :disabled="(typeof model.disabled === 'function' ? model.disabled() : model.disabled)"
        :hint="model.hint || ''"
        :loading="model.loading || $store.getters.getLoaders.fields || false"
        :messages="model.messages || []"
        :rules="rules"
        :validate-on-blur="model.hasOwnProperty('validateOnBlur') ? model.validateOnBlur : false"
    />
</template>

<script>
import field from '@/mixins/field.mixin'

export default {
    name: 'SwitchField',
    mixins: [field],
}
</script>
